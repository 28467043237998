import * as React from 'react'

import { HorizontalAlignment } from '@thg-commerce/enterprise-core'
import {
  GlobalTwoItemImageTextBlock,
  GlobalVideoTextImageCta,
  GlobalWidgetSirius,
  ProvenanceBrandHeader,
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import {
  ImageTextBlock,
  ImageTextBlockProps,
  ItemAlignment,
} from '@thg-commerce/enterprise-widget-image-text-block'
import { Alignment } from '@thg-commerce/gravity-patterns/Header/types'
import { PictureProps } from '@thg-commerce/gravity-system'
import { spacing, styled } from '@thg-commerce/gravity-theme'

import { BaseWidgetProps } from '../types'
import { WidgetTypes } from '../WidgetTypes'

export type ImageTextBlockRendererProps = BaseWidgetProps &
  (
    | (Omit<GlobalTwoItemImageTextBlock, 'query'> & {
        widgetIndex: number
        picture: PictureProps
      })
    | (Omit<GlobalVideoTextImageCta, 'query'> & {
        widgetIndex: number
        picture: PictureProps
      })
    | (Omit<ProvenanceBrandHeader, 'query'> & {
        widgetIndex: number
        picture: PictureProps
      })
    | (Omit<GlobalWidgetSirius, 'query'> & {
        widgetIndex: number
        picture: PictureProps
        border?: {
          width: string
          style: string
        }
      })
  )

type GlobalTwoItemImageTextBlockPropsType = Omit<
  GlobalTwoItemImageTextBlock,
  'query'
> & {
  widgetIndex: number
  picture: PictureProps
}

type GlobalVideoTextImageCtaPropsType = Omit<
  GlobalVideoTextImageCta,
  'query'
> & {
  widgetIndex: number
  picture: PictureProps
}

type ProvenanceBrandHeaderPropsType = Omit<ProvenanceBrandHeader, 'query'> & {
  widgetIndex: number
  picture: PictureProps
}

type GlobalWidgetSiriusPropsType = Omit<GlobalWidgetSirius, 'query'> & {
  widgetIndex: number
  picture: PictureProps
  border?: {
    width: string
    style: string
  }
}

const StyledImageTextBlock = styled(ImageTextBlock)`
  margin: ${spacing(5)} auto;
  max-width: ${(props) => props.theme.site.siteWidth};
`

const GlobalTwoItemImageTextBlockProps = (
  props: GlobalTwoItemImageTextBlockPropsType,
): ImageTextBlockProps => ({
  backgroundColour: props.backgroundColour,
  picture: props.picture,
  textAlign: (props.textAlign as HorizontalAlignment) || undefined,
  title: {
    text: props.itemTitle || '',
  },
  content: {
    text: props.itemText?.content[0].content || undefined,
  },
  CTAs: [
    {
      text: props.itemButton || '',
      url: props.itemURL || undefined,
    },
    {
      text: props.button2Text || '',
      url: props.button2Url || undefined,
    },
  ],
  video: {
    videoUrl: props.videoUrl || '',
  },
  image: {
    urls: {
      small: props.itemImageSmall,
      large: props.itemImage,
    },
    alt: props.itemAlt || '',
    aspectRatio: 'auto',
  },
  surface: {
    alignment: props.itemAlign as ItemAlignment,
    style: 'Background',
  },
  annotation: {
    text: props.imageAnnotationText,
    productText: props.imageProductText,
    productTextUrl: props.imageProductURL,
  },
  URL: props.itemURL || undefined,
  ctaHorizontalAlignment: 'center',
  widgetIndex: props.widgetIndex,
})

const GlobalVideoTextImageCtaProps = (
  props: GlobalVideoTextImageCtaPropsType,
): ImageTextBlockProps => ({
  picture: props.picture,
  surface: { alignment: 'left', style: 'Transparent' },
  video: {
    videoUrl: props.videoUrl || '',
  },
  content: {
    text: props.itemDescription || '',
  },
  CTAs: [
    {
      text: props.itemCTA || '',
      url: props.itemCTALink || '',
    },
  ],
  title: {
    text: props.widgetTitle || '',
  },
  globalVideoTextImageCta: {
    itemImageLink: props.itemImageLink || '',
    itemImage: props.itemImage || '',
  },
  widgetIndex: props.widgetIndex,
})

const ProvenanceBrandHeaderProps = (
  props: ProvenanceBrandHeaderPropsType,
): ImageTextBlockProps => ({
  picture: props.picture,
  title: {
    text: props.itemTitle || '',
    horizontalAlignment: (props.textAlign as HorizontalAlignment) || undefined,
  },
  content: {
    text: props.itemText?.content[0]?.content || undefined,
    horizontalAlignment: (props.textAlign as HorizontalAlignment) || undefined,
  },
  CTAs: [
    {
      text: props.itemButton || '',
      url: props.itemURL || undefined,
    },
    {
      text: props.button2Text || '',
      url: props.button2Url || undefined,
    },
  ],
  video: {
    videoUrl: props.videoUrl || '',
  },
  image: {
    urls: {
      small: props.itemImageSmall,
      large: props.itemImage,
    },
    alt: props.itemAlt || '',
    aspectRatio: '2 / 1',
  },
  surface: {
    alignment: props.itemAlign as ItemAlignment,
    style: 'Transparent',
  },
  URL: props.itemURL || undefined,
  ctaHorizontalAlignment: (props.textAlign as HorizontalAlignment) || undefined,
  provenanceBrandHeader: {
    brand: props.brand || undefined,
    brandId: props.brandId || undefined,
  },
  horizontalAlignment: Alignment.flexStart,
  widgetIndex: props.widgetIndex,
})

const GlobalWidgetSiriusProps = (
  props: GlobalWidgetSiriusPropsType,
): ImageTextBlockProps => ({
  picture: props.picture,
  surface: {
    alignment: props.imagePosition as ItemAlignment,
    style: 'Background',
    border: {
      width: props.border?.width || '1px',
      style: props.border?.style || 'solid',
    },
  },
  title: {
    text: props.title || '',
    horizontalAlignment: 'center',
  },
  content: {
    text: props.subTitle || '',
    horizontalAlignment: 'center',
  },
  CTAs: [
    {
      text: props.button || '',
      url: props.link || undefined,
    },
  ],
  image: props.image && {
    urls: {
      small: props.image,
      large: props.image,
    },
    alt: props.altText || '',
    aspectRatio: 'auto',
  },
  URL: props.link || undefined,
  ctaHorizontalAlignment: 'center',
  horizontalAlignment: Alignment.center,
  widgetIndex: props.widgetIndex,
})

export const ImageTextBlockRenderer = (props: ImageTextBlockRendererProps) => {
  switch (props.__typename) {
    case WidgetTypes.GlobalTwoItemImageTextBlock:
      return (
        <StyledImageTextBlock
          {...GlobalTwoItemImageTextBlockProps(
            props as GlobalTwoItemImageTextBlockPropsType,
          )}
        />
      )
    case WidgetTypes.GlobalVideoTextImageCTA:
      return (
        <StyledImageTextBlock
          {...GlobalVideoTextImageCtaProps(
            props as GlobalVideoTextImageCtaPropsType,
          )}
        />
      )
    case WidgetTypes.ProvenanceBrandHeader:
      return (
        <StyledImageTextBlock
          {...ProvenanceBrandHeaderProps(
            props as ProvenanceBrandHeaderPropsType,
          )}
        />
      )
    case WidgetTypes.GlobalWidgetSirius:
      return (
        <StyledImageTextBlock
          {...GlobalWidgetSiriusProps(props as GlobalWidgetSiriusPropsType)}
        />
      )
    default:
      return null
  }
}
